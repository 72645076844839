
import { Component, Prop, Vue } from 'vue-property-decorator'

import InfoCard from '@/components/StationInfoCard.vue'
import { NodeInfo, NodeInfoFunctions } from '@/types/state'


@Component({
    components: {
      InfoCard
    }
})
export default class StationNavBar extends Vue {
    @Prop()
    setNodeInfoStoreName!: string  // e.g. 'modNode'

    searchValue = ''
    selectedNode = 0

    async setDetailNode(node: NodeInfo): Promise<void> {
        await this.$store.commit(`${this.setNodeInfoStoreName}/SET_NODE_INFO`, { ...node })

        this.$store.dispatch(`${this.setNodeInfoStoreName}/init`)
    }

    containsTerm(target: string, term: string): boolean {
        return target.toLowerCase().includes(term.toLowerCase())
    }

    filterNodesByAlias(nodes: Array<NodeInfo>): Array<NodeInfo> {
        return NodeInfoFunctions.sortDesc(nodes)
    }

    get nodes(): Array<NodeInfo> {
        return this.$store.getters['default/NodeInfos']
    }

    get updatedMeters(): Array<any> {
        return this.$store.getters["default/UpdatedMeters"]
    }

    get filteredNodes(): Array<NodeInfo> {
        return this.filterNodesByAlias(this.nodes.filter(n => this.containsTerm(n.alias, this.searchValue)))
    }

}
