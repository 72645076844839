
import { Component, Vue, Watch } from 'vue-property-decorator'
import VueObserveVisibility from 'vue-observe-visibility'
Vue.use(VueObserveVisibility)

import StationNavBar from '@/components/StationNavBar.vue'
import MeterCard from '@/components/MeterCard.vue'
import { NodeInfo } from '@/types/state'

import { UserRole } from '@/enums'

/* types */
type VueForm = Vue & { validate(): boolean, reset(): void, resetValidation(): void }

/* enums */
enum SnackbarStatus {
    FAIL,
    SUCCESS
}

/* helper functions */
const requiredField  = function(text?: string) { return (input: string): boolean | string => (input !== null && input !== "") || (text ?? "Verplicht") }
const onlyNumbers    = function(text?: string) { return (input: string): boolean | string => /^\d+$/.test(input) || (text ?? "Vul alleen cijfers in") }
const requiredSelect = function(text?: string) { return (input: any): boolean | string    => (input && input != { }) || (text ?? "Verplicht") }
const maxCharacters  = function(max: number, text?: string) {
    return (input: string): boolean | string => (input == "" || (input && input.length <= max)) || (text ?? `Maximaal ${max} karakter${max != 1 ? "s" : ""}`)
}


@Component({
    components: {
        StationNavBar,
        MeterCard
    }
})
export default class EmraPage extends Vue {

  // constants
  STREET_RULES         = [ maxCharacters(64) ]
  HOUSE_NUMBER_RULES   = [ requiredField(), maxCharacters(16) ]
  ZIP_CODE_RULES       = [ requiredField(), maxCharacters(8) ]
  CITY_RULES           = [ maxCharacters(32) ]
  BUILDING_RULES       = [ maxCharacters(64) ]
  COUNTRY_SELECT_RULES = [ requiredSelect() ]

  COMMENT_RULES        = [ maxCharacters(256) ]
  
  METER_NAME_RULES    = [ requiredField(), maxCharacters(32) ]
  METER_ADDRESS_RULES = [ requiredField(), maxCharacters(8), onlyNumbers() ]
  METER_ENC_KEY_RULES = [ requiredField(), maxCharacters(256) ]
  METER_MODEL_RULES   = [ requiredSelect() ]

  METER_UNIT_SELECT_RULES = [ requiredSelect() ]

  SNACKBAR_TIMEOUT_LENGTH = 3000
  SNACKBAR_SUCCESS_COLOR = "#9fc735"
  SNACKBAR_FAIL_COLOR    = "#d62328"

  DATA_TABLE_HEADERS = [
      { text: "Naam", value: "nameWithUnit" },
      { text: "Opslaan", value: "isCollected", sortable: false },
      { text: "Weergeven", value: "isDisplayed", sortable: false }
  ]

  
  // variables
  showAddressForm = false
  showCommentForm = false
  showDisableBoxForm = false
  showNodeUserRelations = false
  updatingValue = false

  addressFormValid = false
  commentFormValid = false

  nodeUserRelTableHeader = [ { value: "userEmail" }, { value: "actions", align: "end" } ]
  usersToDelete: string[] = []

  newAddressStreet = ""
  newAddressHouseNumber = ""
  newAddressCity = ""
  newAddressZipCode = ""
  newAddressBuilding = ""
  newAddressCountrySelect = ""

  newComment = ""

  snackbar      = false
  snackbarText  = ""
  snackbarColor = ""

  headerVisible = true

  loading = false

  showAddMeterForm      = false
  newMeterFormValid     = false
  newMeterName          = ""
  newMeterAddress       = ""
  newMeterEncryptionKey = ""
  newMeterModelSelect   = ""

  showAddUnitForm  = false
  newUnitFormValid = false
  newUnitMUPSelect = ""
  newUnitSaveUnitOnEMRA = true
  newUnitShowUnitToUser = true

  showMeterDetailPopup = false
  editMeterFormValid   = false
  selectedMeter: any   = {}


  // getters: default
  get currentUserId(): string { return this.$store.getters['default/UserId'] }
  get updatedMeters(): any { return this.$store.getters["default/UpdatedMeters"] }

  // getters: emraPageInfo
  get detailNode(): NodeInfo { return this.$store.getters['emraPageInfo/DetailNode'] }
  get detailNodeId(): string { return this.detailNode?.id }
  get isLoaded(): boolean { return this.$store.getters['emraPageInfo/IsPreloaded'] }
  get address(): string { return this.$store.getters['emraPageInfo/Address'] }
  get alias(): string { return this.$store.getters['emraPageInfo/Alias'] }
  get comment(): string { return this.$store.getters['emraPageInfo/Comment'] }
  get nodeUserRelations(): any[] { return this.$store.getters['emraPageInfo/RelatedUsers']?.filter((r: any) => r.nodeId == this.detailNode?.id)?.map((r: any) => ({ userId: r.user.id, userEmail: r.user.userName })) }
  get systemType(): number { return this.$store.getters['emraPageInfo/SystemType'] }
  get countries(): any[] { return this.$store.getters['emraPageInfo/Countries'] }

  @Watch("detailNodeId")
  detailNodeUpdated(): void {
    this.$store.commit("meters/SET_NODE_INFO", this.detailNode)
    this.$store.dispatch("meters/initWithNodeId", this.detailNodeId)
  }

  // getters: identity
  get userRole(): string { return this.$store.getters['identity/role'] }

  get nodeHasUpdates(): boolean {
    return this.updatedMeters?.find((um: any) => um.nodeId == this.detailNode.id) !== undefined
  }

  // getters: meters
  get preloadData(): any   { return this.$store.getters['meters/PreloadData'] }

  get meters(): any[]      { return this.preloadData.meters }
  get meterUnits(): any[]  { return this.preloadData.meterUnits }
  get meterModels(): any[] { return this.preloadData.meterModels }
  get meterUnitPresets(): any[] { return this.preloadData.meterUnitPresets }
  get units(): any[]       { return this.preloadData.units }

  get selectedMeterUnits(): any[] { return this.meterUnits?.filter(mu => mu.meterId == this.selectedMeter?.id) }
  get dataTableItems(): any[] {
      return this.selectedMeterUnits.map(mu => {
          return {
              nameWithUnit: `${mu.unit.referenceName} [${mu.unit.unit.symbol}]`,
              isCollected: mu.isCollected,
              isDisplayed: mu.isDisplayed
          }
      })
  }

  get currentUpdatedMeters(): any[] { return this.updatedMeters?.filter((um: { nodeId: string }) => um.nodeId == this.detailNode?.id).map((um: { meter: any }) => um.meter) }

  get currentForm(): VueForm {
      return this.$refs[this.showAddMeterForm ? "new-meter-form"
                      : (this.showAddUnitForm ? "new-unit-form"
                      : (this.showMeterDetailPopup ? "edit-meter-form" : ""))] as VueForm
  }

  get currentFormValid(): boolean {
      if (this.showAddMeterForm) {
          return this.newMeterFormValid
      } else if (this.showAddUnitForm) {
          return this.newUnitFormValid
      } else if (this.showMeterDetailPopup) {
          return this.editMeterFormValid
      }
      return false
  }


  get inputAlreadyExists(): boolean {
      if (this.showAddMeterForm) {
          return this.meters.findIndex(m => m.nodeId == this.detailNode.id && m.modelId == this.newMeterModelSelect && m.address == this.newMeterAddress) >= 0
      } else if (this.showAddUnitForm) {
          return this.meterUnits.findIndex(mu => mu.meterId == this.selectedMeter?.id && mu.unitId == this.newUnitMUPSelect) >= 0
      } else if (this.showMeterDetailPopup) {
          return !this.selectedMeter || (
                  this.selectedMeter.name === this.newMeterName
              && this.selectedMeter.address === this.newMeterAddress
              && this.selectedMeter.encryptionKey === this.newMeterEncryptionKey)
      }
      return false
  }


  async updateAddress(): Promise<void> {
    const nodeId = this.detailNode?.id
    if (nodeId) {
      try {
        this.updatingValue = true
        const addr = {
          nodeId: nodeId,
          street: this.newAddressStreet,
          houseNumber: this.newAddressHouseNumber,
          zipCode: this.newAddressZipCode,
          city: this.newAddressCity,
          building: this.newAddressBuilding,
          countryId: this.newAddressCountrySelect
        }

        await this.$store.dispatch('emraPageInfo/updateAddress', addr)
        
        this.closePopups()
        this.updatingValue = false
      } catch (_) { "" }
    }
  }

  async updateComment(): Promise<void> {
    const nodeId = this.detailNode?.id
    if (nodeId) {
      try {
        this.updatingValue = true
        const comment = {
          nodeId: nodeId,
          comment: this.newComment
        }

        await this.$store.dispatch('emraPageInfo/updateComment', comment)
        
        this.closePopups()
        this.updatingValue = false
      } catch (_) { "" }
    }
  }

  async toggleOutOfUse(): Promise<void> {
    const nodeId = this.detailNode?.id
    if (nodeId) {
      try {
        this.updatingValue = true
        const update = {
          nodeId: nodeId,
          outOfUse: !this.detailNode?.outOfUse
        }

        await this.$store.dispatch("emraPageInfo/updateOutOfUse", update)
        this.$store.commit("detailNode/SET_OUT_OF_USE", update.outOfUse)
        this.$store.commit("default/UPDATE_NODE_IN_LIST", { ...this.detailNode })

        this.closePopups()
        this.updatingValue = false
      } catch (_) { "" }
    }
  }


  toggleDeleteUser(userId: any): void {
    if (!this.usersToDelete.includes(userId)) {
      this.usersToDelete.push(userId)
    } else {
      this.usersToDelete.splice(this.usersToDelete.indexOf(userId), 1)
    }
  }

  async deleteSelectedUsers(): Promise<void> {
    const nodeId = this.detailNode?.id
    if (nodeId && this.usersToDelete.length > 0) {
      let deleted: number[] = []
      for (let i = 0; i < this.usersToDelete.length; i++) {
        const userId = this.usersToDelete[i]
        try {
          await this.$store.dispatch("adminCrud/Delete", { Model: "NodeUserRelation", Entity: { UserId: userId, NodeId: nodeId } })
            .then((result: any) => {
              if (!result || !(result?.err)) {
                this.$store.commit("emraPageInfo/DELETE_USER_RELATION", { nodeId: nodeId, userId: userId })
                deleted.push(i)
              }
            })
        } catch (_) { break }
      }
      deleted.sort((a, b) => b - a).forEach((d: number) => {
        this.usersToDelete.splice(d, 1)
      })
    }
  }
  
  vClickOutsideIncluded(): HTMLElement[] {
      const classes = ["includeClickOutside"]
      const result: HTMLElement[] = []

      classes.forEach(name => {
          const elements = document.getElementsByClassName(name);
          (Array.from(elements) as HTMLElement[]).forEach(el => {
              result.push(el)
          })
      })

      return result
  }

  closePopups(): void {
    this.showAddressForm = false
    this.showCommentForm = false
    this.showDisableBoxForm = false
    this.showNodeUserRelations = false
    this.usersToDelete = []
  }


  async navigateToDetailPage(page: string) {
    switch (page) {
      case 'data':      await this.$store.commit('detailNode/SET_NODE_INFO', { ...this.detailNode })
                        this.$router.push({ name: this.userRole == UserRole.User ? "DataPage" : (this.userRole != UserRole.None ? "ModDataPage" : "UserHome") })
                        break
      case 'mod-node':  await this.$store.commit('modNode/SET_NODE_INFO', { ...this.detailNode })
                        this.$router.push({ name: this.userRole == UserRole.Moderator || this.userRole == UserRole.Admin ? "ModNode" : "UserHome" })
                        break
      case 'meters':    await this.$store.commit('meters/SET_NODE_INFO', { ...this.detailNode })
                        this.$store.dispatch('meters/init')
                        this.$router.push({ name: this.userRole == UserRole.Moderator || this.userRole == UserRole.Admin ? "Meters" : "UserHome" })
                        break
      default:          break
    }
  }


  @Watch("showMeterDetailPopup")
  meterDetailPopupToggled(newVal: boolean) {
      if (newVal && this.selectedMeter) {
          this.newMeterName = this.selectedMeter.name
          this.newMeterAddress = this.selectedMeter.address
          this.newMeterEncryptionKey = this.selectedMeter.encryptionKey
      } else if (!newVal && this.selectedMeter) {
          this.newMeterName = ""
          this.newMeterAddress = ""
          this.newMeterEncryptionKey = ""
      }
  }

  formatDate(date: string): string {
      return date.substring(8, 10) + "/" + date.substring(5, 7) + "/" + date.substring(0, 4)
  }

  hidePopupForms(): void {
      this.showAddMeterForm = false
      this.showAddUnitForm = false
      this.showMeterDetailPopup = false
  }

  showMeterDetails(meter: any): void {
      this.selectedMeter = meter
      this.showMeterDetailPopup = true
  }

  updateMeters(): void {
      // send meters to EMRA-box
      this.$store.dispatch('default/deleteUpdatedMeters', this.detailNode.id)
  }

  validateAndSaveData(): boolean {
      if (!this.currentForm.validate()) return false
      this.$store.commit("meters/SET_PRELOADED", false)

      let modelName = ""
      let stateName = ""
      let selectName = ""
      let data = { }

      // These variables are necessary to commit ADD_UPDATED_METER in case a meter was updated
      let nodeId = this.detailNode.id
      let meterId = this.selectedMeter.id

      if (this.showAddMeterForm) {
          modelName = "Meter"; stateName = "meters"
          data = {
              NodeId: this.detailNode.id,
              Name: this.newMeterName,
              Address: this.newMeterAddress,
              EncryptionKey: this.newMeterEncryptionKey,
              ModelId: this.newMeterModelSelect
          }
      } else if (this.showAddUnitForm && this.selectedMeter) {
          modelName = "MeterUnit"; stateName = "meterUnits"
          data = {
              MeterId: this.selectedMeter.id,
              UnitId: this.newUnitMUPSelect,
              IsCollected: this.newUnitSaveUnitOnEMRA,
              IsDisplayed: this.newUnitShowUnitToUser
          }
      } else if (this.showMeterDetailPopup && !this.showAddUnitForm && this.selectedMeter) {
          modelName = "Meter"; stateName = "meters"
          data = {
              NodeId: this.detailNode.id,
              Name: this.newMeterName,
              Address: this.newMeterAddress,
              EncryptionKey: this.newMeterEncryptionKey,
              ModelId: this.selectedMeter.model.id,
              Id: this.selectedMeter.id
          }
      }

      let success = false

      if (modelName != "") {
          if (modelName == "Meter" && this.showMeterDetailPopup && !this.showAddUnitForm) {
              try {
                  this.$store.dispatch("meters/editMeter", data)
                      .then((result: any) => {
                          if (!result.err) {
                              this.$store.commit("default/ADD_UPDATED_METER", {
                                  nodeId: nodeId,
                                  meterId: meterId
                              })
                              this.snack("Data succesvol opgeslagen", SnackbarStatus.SUCCESS)
                              success = true
                          }
                      })
              } catch (_) {
                  this.snack("Fout: data niet opgeslagen", SnackbarStatus.FAIL)
                  success = false
              }
          } else {
              try {
                  this.$store.dispatch("adminCrud/Create", { Model: modelName, Entity: data })
                      .then((result: any) => {
                          if (!result.err) {
                              if (stateName != "") {
                                  if (stateName == "meters") {
                                      const meterModel = this.meterModels.find(mm => mm.id == this.newMeterModelSelect)
                                      result.entity["Model"] = meterModel
                                      meterId = result.entity?.Id
                                  } else if (stateName == "meterUnits") {
                                      const meterUnitPreset = this.meterUnitPresets.find(mup => mup.id == this.newUnitMUPSelect)
                                      meterUnitPreset["Unit"] = this.units.find(u => u.id == meterUnitPreset.unit.unitId)
                                      result.entity["Unit"] = meterUnitPreset
                                  }
                                  this.$store.dispatch('meters/addUpdatedMeter', { NodeId: nodeId, MeterId: meterId })
                                  this.$store.commit("meters/ADD_DATA", { Model: stateName, Entity: result.entity })
                                  this.$store.commit("default/ADD_UPDATED_METER", {
                                    nodeId: nodeId,
                                    meterId: meterId
                                  })
                              }
                              if (selectName != "") {
                                  this.$data[selectName] = result.entity["Id"]
                                  const el = document.getElementById(modelName + "Select") as HTMLInputElement
                                  el.value = result.entity["Id"]
                                  el.dispatchEvent(new Event("input"))
                              }
                              this.snack("Data succesvol opgeslagen", SnackbarStatus.SUCCESS)
                              success = true
                              if (this.showAddUnitForm) this.showAddUnitForm = false
                              else                      this.hidePopupForms()
                          }
                          else {
                              this.snack("Fout: data niet opgeslagen", SnackbarStatus.FAIL)
                              success = false
                          }
                      })
              } catch (_) {
                  this.snack("Fout: data niet opgeslagen", SnackbarStatus.FAIL)
                  success = false
              }
          }
      }

      this.$store.commit("meters/SET_PRELOADED", true)

      return success
  }

  snack(text: string, status: SnackbarStatus): void {
      this.snackbarColor = status === SnackbarStatus.FAIL ? this.SNACKBAR_FAIL_COLOR : this.SNACKBAR_SUCCESS_COLOR
      this.snackbarText  = text
      this.snackbar      = true
  }


  headerVisibilityChanged(isVisible: boolean): void {
      this.headerVisible = isVisible
  }



  async created(): Promise<void> {
      window.addEventListener("keydown", (k) => {
          if (this.showAddUnitForm) {
              if (k.key == "Escape") this.showAddUnitForm = false
          } else if (this.showAddMeterForm || this.showMeterDetailPopup) {
              if (k.key == "Escape") this.hidePopupForms()
          }
      })

      this.$store.dispatch('addEmra/init')
      await this.$store.commit('meters/SET_NODE_INFO', { ...this.detailNode })
      this.$store.dispatch('meters/init')

      if (this.detailNode && this.userRole == UserRole.Moderator || this.userRole == UserRole.Admin) {
          await this.$store.dispatch('emraPageInfo/init').catch((err: any) => err)
      }
  }

}
